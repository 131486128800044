<template>
  <div class="flex flex-wrap items-center text-sm leading-none text-blue-600" data-testid="breadcrumbs">
    <template v-for="(crumb, i) in mappedBreadcrumbs" :key="crumb">
      <template v-if="styleType == BreadcrumbSlashesCompactStyleType">
        <span v-if="i > 0" class="mx-1" data-id="separator">/</span>
      </template>

      <template v-else>
        <ChevronRightIcon v-if="i > 0" class="h-5 w-5 flex-shrink-0 text-blue-600 ml-2"></ChevronRightIcon>
      </template>

      <router-link
        v-if="crumb.link"
        :to="crumb.link"
        class="text-sm font-bold"
        role="link"
        :aria-label="crumb.text"
        :class="[
          getLinkClassList(i),
          homeLink && i === 0 ? 'text-blue-400 hover:text-blue-600' : 'text-blue-600 hover:text-blue-700',
        ]"
      >
        <template v-if="homeLink && i === 0">
          <HomeIcon class="h-5 w-5 flex-shrink-0"></HomeIcon>
        </template>

        <template v-else>
          {{ crumb.text }}
        </template>
      </router-link>

      <span
        v-else
        :aria-label="crumb.text"
        class="text-sm font-bold text-gray-500 hover:text-gray-700"
        :class="getLinkClassList(i)"
        >{{ crumb.text }}</span
      >
    </template>
  </div>
</template>

<script lang="ts">
import BreadcrumbsProp from "@/base/props/BreadcrumbProps.ts";
import RouteLocationRawSchema from "@/app/router/schemas/RouteLocationRawSchema.ts";
import ChevronRightIcon from "@heroicons/vue/20/solid/ChevronRightIcon";
import HomeIcon from "@heroicons/vue/20/solid/HomeIcon";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";

export const BreadcrumbSlashesCompactStyleType = "slashesCompact";
export const BreadcrumbChevronStyleType = "chevron";

export type BreadcrumbStyleType = typeof BreadcrumbChevronStyleType | typeof BreadcrumbSlashesCompactStyleType;

/** Freeze constants as their contents can still be modified and mess with reactivity */
export const BreadcrumbStyleTypes = Object.freeze([BreadcrumbSlashesCompactStyleType, BreadcrumbChevronStyleType]);

export default defineComponent({
  name: "BaseBreadcrumb",
  components: {
    ChevronRightIcon,
    HomeIcon,
  },

  expose: [],
  props: {
    /** Home icon link */
    homeLink: {
      type: [Object, String] as PropType<RouteLocationRaw>,
      required: false,
      default: undefined,
      validator: (value: unknown) => RouteLocationRawSchema.safeParse(value).success,
    },

    /** The breadcrumbs. array of arrays keyed with link & text. Set link to '' to have no linking for that crumb */
    ...BreadcrumbsProp,

    /** The style of the breadcrumb as defined by */
    styleType: {
      type: String as PropType<BreadcrumbStyleType>,
      required: false,
      default: BreadcrumbSlashesCompactStyleType,
      validator(value: unknown) {
        /* istanbul ignore if -- @preserve */
        if (typeof value !== "string") {
          return false;
        }

        return BreadcrumbStyleTypes.includes(value);
      },
    },
  },

  computed: {
    BreadcrumbSlashesCompactStyleType() {
      return BreadcrumbSlashesCompactStyleType;
    },

    mappedBreadcrumbs() {
      return [...(this.homeLink != null ? [{ link: this.homeLink, text: "Home" }] : []), ...this.breadcrumbs];
    },
  },

  methods: {
    getLinkClassList(index: number) {
      const classList = [];

      if (index > 0) {
        if (this.styleType === BreadcrumbSlashesCompactStyleType) {
          classList.push("ml-1");
        }
        if (this.styleType === BreadcrumbChevronStyleType) {
          classList.push("ml-2");
        }
      }

      return classList;
    },
  },
});
</script>
