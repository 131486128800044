import z from "zod";

const PaginationCursorMetaSchema = z.object({
  /** The url of the api endpoint */
  path: z.string(),
  /** The total number of items per cursor page */
  per_page: z.number(),
  /**
   * The next cursor hash string.
   *
   * Null if no next page.
   *
   * Pass this cursor to the next request to get the next page.
   */
  next_cursor: z.string().nullable(),
  /**
   * The previous cursor hash string.
   *
   * Null if no previous page.
   * Pass this cursor to the next request to get the previous page.
   */
  prev_cursor: z.string().nullable(),
});

export default PaginationCursorMetaSchema;
