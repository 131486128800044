import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import { createRouteLocation } from "@/app/router/functions/NavigateToErrorPage.ts";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

/**
 * Makes sure the authenticated user is a super admin
 * @param to the to route location
 * @returns returns boolean to continue or to not continue
 */
export default function redirectToSuperAdminHomeGuard(to: RouteLocationNormalized): RouteLocationRaw | boolean {
  const authenticationStore = useAuthenticationStore();

  if (to.meta.isSuperAdmin ?? false) {
    if (authenticationStore.isAuthenticated && authenticationStore.isSuperAdmin) {
      return true;
    }

    return createRouteLocation(404, undefined);
  }
  return true;
}
