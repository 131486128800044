<template>
  <div v-show="hasNextCursor">
    <slot name="next-cursor" :next-cursor="nextCursor" />
  </div>

  <div v-show="hasPreviousCursor">
    <slot name="previous-cursor" :previous-cursor="previousCursor" />
  </div>
</template>

<script lang="ts">
import type PaginationCursorMetaInterface from "@/base/interfaces/PaginationCursorInterface";
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  name: "BaseCursorPaginationHeadless",
  expose: [],
  props: {
    /** The cursor metadata from the api response. */
    cursorMeta: {
      type: Object as PropType<PaginationCursorMetaInterface>,
      required: true,
    },
  },

  computed: {
    nextCursor() {
      return this.cursorMeta.next_cursor;
    },

    previousCursor() {
      return this.cursorMeta.prev_cursor;
    },

    hasNextCursor() {
      return this.nextCursor !== null;
    },

    hasPreviousCursor() {
      return this.previousCursor !== null;
    },
  },
});
</script>
