import type { RouteLocationNamedRaw, RouteLocationNormalized } from "vue-router";
import convertBackendRouteToRoute from "../functions/BackendRouteToRouteConverter.ts";

/**
 * Redirects the user to another route if the routeName query string is present.
 * Useful where backend identifiers can be used to navigate to frontend routes.
 *
 * We map the backend route name to the frontend route via the `BackendRouteToRouteConverter`.
 * @param to The route that we are navigating to.
 * @returns The route that we are navigating, the error route if we can't resolve the route
 * or true if we don't need to redirect.
 */
export default function routeNameQueryStringRedirectGuard(
  to: RouteLocationNormalized
): RouteLocationNamedRaw | boolean {
  const backendRouteNameQueryString = to.query["routeName"];

  if (typeof backendRouteNameQueryString !== "string") {
    return true;
  }

  return convertBackendRouteToRoute(backendRouteNameQueryString, to.query);
}
