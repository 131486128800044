import type { RouteLocationNamedRaw, RouteParamsRawGeneric } from "vue-router";
import backendToFrontendRouteMap from "../constants/BackendToFrontendRouteMap.ts";
import { createRouteLocation } from "./NavigateToErrorPage.ts";
import * as Sentry from "@sentry/vue";

/**
 * Converts a backend route to a frontend route.
 * @param routeName The backend route name
 * @param params The query string parameters
 * @returns The vue router route
 */
export default function convertBackendRouteToRoute(
  routeName: string,
  params: Record<string, unknown>
): RouteLocationNamedRaw {
  const route = backendToFrontendRouteMap.find((r) => r.backendRouteName === routeName);

  if (route === undefined) {
    Sentry.captureException(new Error(`Failed to find route ${routeName}`));

    return createRouteLocation(404, "Failed to find page");
  }

  const routeParams: RouteParamsRawGeneric = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const frontendParamName in route.queryStringsToRouteParamsMap) {
    /* istanbul ignore else -- @preserve */
    if (Object.prototype.hasOwnProperty.call(route.queryStringsToRouteParamsMap, frontendParamName)) {
      if (!(frontendParamName in params)) {
        Sentry.captureException(new Error(`Failed to find parameter ${frontendParamName} in route ${routeName}`));

        return createRouteLocation(404, "Failed to find page");
      }

      const value = params[frontendParamName];

      if (typeof value !== "string" && typeof value !== "number") {
        Sentry.captureException(
          new Error(`Failed to find value for backend param ${frontendParamName} in route ${routeName}`)
        );

        return createRouteLocation(404, "Failed to find page");
      }

      routeParams[frontendParamName] = value;
    }
  }

  return {
    name: route.frontendRouteName,
    params: routeParams,
  };
}
