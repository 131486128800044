import type { ErrorBagInterface } from "@/validation/classes/ErrorBag.ts";
import ErrorBag from "@/validation/classes/ErrorBag.ts";
import { unref, type Ref } from "vue";
import { ZodSchema } from "zod";

export type ValidateReturnType<T> =
  | {
      data: T;
      success: true;
      errorBag: ErrorBagInterface;
    }
  | {
      data: undefined;
      success: false;
      errorBag: ErrorBagInterface;
    };

/**
 * Reusable logic for interacting with validation in vue components
 * @author Jamie Wood
 * @param schema the zod schema for parsing the data
 * @returns the validation composable
 */
export default function useValidatorComposable<T>(schema: Ref<ZodSchema<T>>): {
  validate: (data: Ref<unknown> | unknown) => ValidateReturnType<T>;
} {
  if (!(schema.value instanceof ZodSchema)) throw new Error("schema must be an instance of ZodSchema");
  const validate = (data: unknown): ValidateReturnType<T> => {
    const res = schema.value.safeParse(unref(data));
    const isSuccess = res.success;
    let errorBag = ErrorBag.new({});

    if (!res.success) {
      const errorBagErrors = {} as Record<string, string[]>;

      res.error.issues.forEach((issue) => {
        if ("unionErrors" in issue) {
          issue.unionErrors.forEach((unionError) => {
            unionError.issues.forEach((unionIssue) => {
              const key = unionIssue.path.join(".");

              if (errorBagErrors[key]) {
                errorBagErrors[key].push(unionIssue.message);
              } else {
                errorBagErrors[key] = [unionIssue.message];
              }
            });
          });
        } else {
          const key = issue.path.join(".");

          errorBagErrors[key] = [issue.message];
        }
      });

      errorBag = ErrorBag.new(errorBagErrors);
    }

    if (!isSuccess) {
      return {
        data: undefined,
        success: false,
        errorBag,
      };
    }

    return {
      data: res.data,
      success: true,
      errorBag,
    };
  };

  return {
    validate,
  };
}
