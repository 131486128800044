/* eslint-disable @typescript-eslint/promise-function-async */
import LogoutGuard from "@/auth/guards/LogoutGuard.ts";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import currentCompanyGuard from "@/company/guards/CurrentCompanyGuard.ts";
import PermissionEnum from "@/permissions/enums/PermissionEnum.ts";
import createCompanyPermissionGuard from "@/permissions/guards/CompanyPermissionGuard.ts";
import createSitePermissionGuard from "@/permissions/guards/SitePermissionGuard.ts";
import type SettingsTab from "@/settings/interfaces/SettingsTabInterface";
import RedirectToSuperAdminHomeGuard from "@/superAdmin/guards/RedirectToSuperAdminHomeGuard.ts";
import taskHasDraftVersionRedirectGuard from "@/task/guards/TaskHasDraftVersionRedirectGuard.ts";
import { h } from "vue";
import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { RouterView } from "vue-router";

/**
 * Return props for an authenticated user
 * @returns props
 */
const getAuthenticatedUserIdProp = /* istanbul ignore next */ (): { userId: number | undefined } => {
  const auth = /* istanbul ignore next */ useAuthenticationStore();

  return /* istanbul ignore next -- @preserve */ {
    /** Authenticated user id */
    userId: auth.authenticatedUser?.id,
  };
};

const getNumericProp = /* istanbul ignore next */ <NameType extends string>(
  name: NameType,
  route: RouteLocationNormalized
): Pick<Record<NameType, number | undefined>, NameType> => {
  return {
    [name]: route.params[name] !== undefined ? Number(route.params[name]) : undefined,
  } as Record<NameType, number | undefined>;
};

const getUserIdProp = /* istanbul ignore next */ (route: RouteLocationNormalized): { userId: number | undefined } =>
  getNumericProp("userId", route);

const getSiteIdProp = /* istanbul ignore next */ (route: RouteLocationNormalized): { siteId: number | undefined } =>
  getNumericProp("siteId", route);

enum AppRouteNames {
  issueDetail = "issueDetail",
}

const routes: RouteRecordRaw[] = [
  {
    path: "",
    component: /* istanbul ignore next */ () => import("@/home/pageLayouts/newHomePageLayout/TheHomePageContainer.vue"),
    beforeEnter: [RedirectToSuperAdminHomeGuard, currentCompanyGuard],
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        redirect: /* istanbul ignore next */ () => {
          return { name: "homeRoute" };
        },
        children: [
          {
            path: "",
            name: "homeRoute",
            component: /* istanbul ignore next */ () => import("@/home/pages/TheHomePage.vue"),
            redirect: /* istanbul ignore next */ () => {
              return {
                name: "userTasksMyTasks",
              };
            },
            children: [
              {
                path: "tasks/my-venue-tasks",
                name: "userTasksMyTasks",
                props: /* istanbul ignore next */ () => ({
                  ...getAuthenticatedUserIdProp(),
                  lense: "my-venue-tasks",
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userTaskListIndex/UserTasksIndexPage.vue"),
              },
              {
                path: "tasks/all-venue-tasks",
                name: "userTasksAllVenueTasks",
                props: /* istanbul ignore next */ () => ({
                  ...getAuthenticatedUserIdProp(),
                  lense: "all-venue-tasks",
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userTaskListIndex/UserTasksIndexPage.vue"),
              },
              {
                path: "tasks/created-by-me",
                name: "userTasksCreatedByMe",
                props: /* istanbul ignore next */ () => ({
                  ...getAuthenticatedUserIdProp(),
                  lense: "created-by-me",
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userTaskListIndex/UserTasksIndexPage.vue"),
              },
              {
                path: "tasks/my-approvals",
                name: "userTasksMyApprovals",
                props: /* istanbul ignore next */ () => ({
                  ...getAuthenticatedUserIdProp(),
                  lense: "my-approvals",
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userTaskListIndex/UserTasksIndexPage.vue"),
              },
              {
                path: "tasks/expired",
                name: "userExpiredUserTasks",
                props: /* istanbul ignore next */ () => ({
                  ...getAuthenticatedUserIdProp(),
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userExpiredUserTasksIndex/TheUserExpiredUserTasksIndexPage.vue"),
              },
            ],
          },
          {
            path: "tasks/expired/:expiredUserTaskId",
            name: "userExpiredUserTaskDetail",
            component: /* istanbul ignore next */ () =>
              import("@/task/pages/userExpiredUserTaskDetail/TheUserExpiredUserTaskDetailPage.vue"),
            props: /* istanbul ignore next */ (route) => ({
              expiredUserTaskId: Number(route.params["expiredUserTaskId"]),
              indexLocation: {
                name: "userExpiredUserTasks",
              },
            }),
          },
          {
            path: "tasks/:userTaskId",
            name: "userTaskDetail",
            props: /* istanbul ignore next */ (route) => ({
              userTaskId: Number(route.params["userTaskId"]),
              indexLocation: {
                name: "userTasksMyTasks",
              },
              /**
               * @todo This was a hack fix for a demo, this needs changing when we settle down on a pattern
               * for handling back urls.
               */
              allowBackNavigation:
                "allowBackNavigation" in route.query &&
                route.query["allowBackNavigation"] !== "" &&
                route.query["allowBackNavigation"] != null
                  ? Boolean(route.query["allowBackNavigation"])
                  : undefined,
            }),
            component: /* istanbul ignore next */ () => import("@/task/pages/userTaskDetail/TheUserTaskDetailPage.vue"),
          },
        ],
      },
      {
        path: "manage",
        children: [
          {
            name: "userManage",
            path: "",
            beforeEnter: [currentCompanyGuard, createCompanyPermissionGuard(PermissionEnum.manageView)],
            component: /* istanbul ignore next */ () =>
              import("@/company/pages/manageIndex/TheCompanyManageIndexPage.vue"),
          },
          {
            path: ":slug",
            children: [
              {
                path: "",
                name: "manageTasksIndex",
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/task/pages/manageTasksIndex/TheManageTasksIndexPage.vue"),
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  slug: route.params["slug"],
                }),
                beforeEnter: createCompanyPermissionGuard(PermissionEnum.manageView),
              },
              {
                path: "create",
                name: "userManageTaskCreate",
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/task/pages/manageTaskCreate/TheManageTaskCreateContainer.vue"),
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  slug: route.params["slug"],
                }),
                beforeEnter: createCompanyPermissionGuard(PermissionEnum.manageCreate),
              },
              {
                path: "templates",
                children: [
                  {
                    path: "",
                    name: "manageTemplateTasksIndex",
                    component: /* istanbul ignore next -- @preserve */ () =>
                      import("@/task/pages/userManageTemplateTasksIndex/TheUserManageTemplateTasksIndexContainer.vue"),
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      slug: route.params["slug"],
                    }),
                    beforeEnter: createCompanyPermissionGuard(PermissionEnum.manageCreate),
                  },
                  {
                    path: ":taskId",
                    name: "userManageTemplateTaskDetail",
                    component: /* istanbul ignore next */ () =>
                      import("@/task/pages/userTemplateTaskManageDetail/TheUserTemplateTaskManageDetailContainer.vue"),
                    props: /* istanbul ignore next */ (route) => ({
                      taskId: Number(route.params["taskId"]),
                      slug: route.params["slug"],
                    }),
                    beforeEnter: createCompanyPermissionGuard(PermissionEnum.manageCreate),
                  },
                ],
              },
              {
                path: ":taskId",
                name: "userManageTaskDetail",
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/taskManageDetail/TheUserManageTaskDetailPage.vue"),
                props: /* istanbul ignore next */ (route) => ({
                  taskId: Number(route.params["taskId"]),
                  slug: route.params["slug"],
                }),
                beforeEnter: [
                  /* istanbul ignore next -- @preserve */ (to) =>
                    taskHasDraftVersionRedirectGuard(to, (slug, draftTaskVersionId) => ({
                      name: "taskVersionUpdate",
                      params: { slug, taskVersionId: draftTaskVersionId },
                    })),

                  createCompanyPermissionGuard(PermissionEnum.manageShow),
                ],
              },
              {
                path: "task-versions/:taskVersionId/update",
                name: "taskVersionUpdate",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  taskVersionId: Number(route.params["taskVersionId"]),
                  slug: route.params["slug"],
                }),
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/task/pages/taskVersionUpdate/TheUserTaskVersionUpdatePage.vue"),
                beforeEnter: createCompanyPermissionGuard(PermissionEnum.manageUpdate),
              },
            ],
          },
        ],
      },
      {
        path: "/staff",
        name: "companyStaff",
        children: [
          {
            path: "",
            name: "companyStaffIndex",
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/company/views/settingsCompanyStaffIndex/TheSettingsCompanyStaffIndexView.vue"),
            beforeEnter: createCompanyPermissionGuard(PermissionEnum.companyStaffView),
          },
          {
            path: "create",
            name: "companyStaffCreate",
            beforeEnter: [currentCompanyGuard, createCompanyPermissionGuard(PermissionEnum.companyStaffInvite)],
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/company/views/settingsCompanyStaffCreate/TheSettingsCompanyStaffCreateView.vue"),
          },
          {
            path: ":userId",
            name: "companyStaffDetail",
            props: /* istanbul ignore next -- @preserve */ (route) => ({
              ...getUserIdProp(route),
            }),
            redirect: /* istanbul ignore next -- @preserve */ () => {
              return { name: "companyStaffDetailSettings" };
            },
            beforeEnter: createCompanyPermissionGuard(PermissionEnum.companyStaffShow),
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/company/pages/companyStaffDetail/TheCompanyStaffDetailPage.vue"),
            children: [
              {
                name: "companyStaffDetailSettings",
                path: "settings",
                component: /* istanbul ignore next -- @preserve  */ () =>
                  import("@/company/pages/companyStaffDetailSettings/CompanyStaffDetailSettingsPage.vue"),
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getUserIdProp(route),
                }),
                beforeEnter: currentCompanyGuard,
              },
            ],
          },
        ],
      },
      {
        path: "/sites",
        name: "companySites",
        component: RouterView,
        children: [
          {
            path: "",
            name: "companySitesIndex",
            beforeEnter: createCompanyPermissionGuard(PermissionEnum.companySitesView),
            component: /* istanbul ignore next -- @preserve */ () =>
              import("@/site/views/settingsCompanySitesIndex/TheSettingsCompanySitesIndexView.vue"),
          },
          {
            path: "create",
            name: "companySiteCreate",
            beforeEnter: createCompanyPermissionGuard(PermissionEnum.companySitesCreate),
            component: /* istanbul ignore next -- @preserve */ () =>
              import("@/site/views/settingsCompanySiteCreate/TheSettingsCompanySiteCreateView.vue"),
          },
          {
            path: ":siteId",
            name: "companySiteDetail",
            beforeEnter: [currentCompanyGuard, createSitePermissionGuard(PermissionEnum.companySitesShow)],
            props: /* istanbul ignore next -- @preserve */ (route) => ({
              ...getSiteIdProp(route),
            }),
            redirect: /* istanbul ignore next -- @preserve */ () => {
              return { name: "companySiteDetailOverview" };
            },
            component: /* istanbul ignore next -- @preserve */ () =>
              import("@/site/pages/companySiteDetail/TheCompanySiteDetailPage.vue"),
            children: [
              {
                path: "overview",
                name: "companySiteDetailOverview",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/site/views/companySiteDetailOverview/TheCompanySiteDetailOverviewContainer.vue"),
              },
              {
                path: "staff",
                name: "companySiteDetailStaff",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/site/views/companySiteDetailStaff/TheCompanySiteDetailStaffContainer.vue"),
              },

              {
                path: "staff",
                name: "companySiteDetailStaff",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                component: RouterView,
                redirect: /* istanbul ignore next */ () => {
                  return { name: "companySiteDetailStaffIndex" };
                },
                children: [
                  {
                    path: "",
                    name: "companySiteDetailStaffIndex",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                    }),
                    component: /* istanbul ignore next -- @preserve */ () =>
                      import("@/site/views/companySiteDetailStaff/TheCompanySiteDetailStaffContainer.vue"),
                  },
                  {
                    path: ":siteStaffId",
                    name: "companySiteDetailStaffDetail",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getNumericProp("siteStaffId", route),
                    }),
                    component: /* istanbul ignore next */ () =>
                      import("@/site/pages/companySiteStaffDetail/CompanySiteStaffDetailPage.vue"),
                    redirect: /* istanbul ignore next */ (route) => {
                      return {
                        name: "companySiteDetailStaffDetailSettingsTab",
                        params: { slug: "profile-settings", ...route.params },
                      };
                    },
                    children: [
                      {
                        path: ":slug?",
                        name: "companySiteDetailStaffDetailSettingsTab",
                        props: /* istanbul ignore next -- @preserve */ (route) => {
                          const { siteId } = getSiteIdProp(route);
                          const { siteStaffId } = getNumericProp("siteStaffId", route);

                          return {
                            siteId,
                            siteStaffId,
                            slug: route.params["slug"],
                            tabRoute: (tab: SettingsTab) => ({
                              name: "companySiteDetailStaffDetailSettingsTab",
                              params: {
                                slug: tab.slug,
                                siteId,
                                siteStaffId,
                              },
                            }),
                          };
                        },
                        component: /* istanbul ignore next -- @preserve  */ () =>
                          import(
                            "@/site/views/companySiteStaffDetailSettings/CompanySiteStaffDetailSettingsTabView.vue"
                          ),
                      },
                    ],
                  },
                ],
              },

              {
                path: "documents",
                name: "companySiteDetailDocuments",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                component: RouterView,
                redirect: /* istanbul ignore next */ () => {
                  return { name: "companySiteDetailDocumentsIndex" };
                },
                children: [
                  {
                    path: "",
                    name: "companySiteDetailDocumentsIndex",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                    }),
                    component: /* istanbul ignore next -- @preserve */ () =>
                      import(
                        "@/document/pages/companySiteDetailDocumentsIndex/CompanySiteDetailDocumentsIndexPage.vue"
                      ),
                  },
                ],
              },

              {
                path: "tasks",
                name: "companySiteDetailTasks",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/task/views/companySiteDetailUserTask/TheCompanySiteDetailUserTaskView.vue"),
                redirect: /* istanbul ignore next */ () => {
                  return { name: "companySiteDetailUserCurrentTasks" };
                },
                children: [
                  {
                    path: "current",
                    name: "companySiteDetailUserCurrentTasks",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                    }),
                    component: /* istanbul ignore next */ () =>
                      import("@/task/pages/companySiteDetailUserTaskList/TheCompanySiteDetailUserTaskListPage.vue"),
                  },
                  {
                    path: ":slug",
                    name: "companySiteDetailUserTaskRecords",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                      slug: route.params["slug"],
                    }),
                    component: /* istanbul ignore next */ () =>
                      import(
                        "@/task/pages/companySiteDetailUserTaskRecords/TheCompanySiteDetailUserTaskRecordsIndexPage.vue"
                      ),
                  },
                ],
              },
              {
                path: "tasks/:userTaskId",
                name: "companySiteDetailUserTaskDetail",
                props: /* istanbul ignore next */ (route) => ({
                  userTaskId: Number(route.params["userTaskId"]),
                  indexLocation: {
                    name: "companySiteDetailUserCurrentTasks",
                    params: {
                      siteId: Number(route.params["siteId"]),
                    },
                  },
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userTaskDetail/TheUserTaskDetailPage.vue"),
              },
              {
                path: "tasks/expired/:expiredUserTaskId",
                name: "companySiteDetailUserExpiredUserTaskDetail",
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/userExpiredUserTaskDetail/TheUserExpiredUserTaskDetailPage.vue"),
                props: /* istanbul ignore next */ (route) => ({
                  expiredUserTaskId: Number(route.params["expiredUserTaskId"]),
                  indexLocation: {
                    name: "companySiteDetailUserCurrentTasks",
                    params: {
                      siteId: Number(route.params["siteId"]),
                    },
                  },
                }),
              },
              {
                path: "settings",
                name: "companySiteDetailSettings",
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/site/views/companySiteDetailSettings/TheCompanySiteDetailSettingsContainer.vue"),
              },

              {
                path: "assets",
                name: "companySiteDetailAssets",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                component: /* istanbul ignore next */ () =>
                  import("@/site/views/companySiteDetailAssets/CompanySiteDetailAssetsView.vue"),
                redirect: /* istanbul ignore next */ () => {
                  return { name: "companySiteDetailAssetsIndex" };
                },
                children: [
                  {
                    path: "",
                    name: "companySiteDetailAssetsIndex",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                    }),
                    component: /* istanbul ignore next */ () =>
                      import(
                        "@/site/views/companySiteDetailBuildingConfigurationAllAssets/TheCompanySiteDetailBuildingConfigurationAllAssetsView.vue"
                      ),
                  },
                  {
                    path: ":assetId",
                    name: "companySiteDetailAssetDetail",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                      assetId: Number(route.params["assetId"]),
                      assetDetailRouteName: "companySiteDetailAssetDetailOverview",
                      assetDetailRoomsRouteName: "companySiteDetailAssetDetailRoomsIndex",
                      assetDetailAssetsRouteName: "companySiteDetailAssetDetailAssetsIndex",
                      assetDetailAreasRouteName: "companySiteDetailAssetDetailAreasIndex",
                      assetDetailChecksRouteName: "companySiteDetailAssetDetailChecksIndex",
                      assetDetailActionsRouteName: "companySiteDetailAssetDetailActionsIndex",

                      breadcrumbs: [{ link: { name: "companySiteDetailAssetsIndex" }, text: "Site Assets" }],
                    }),
                    component: /* istanbul ignore next */ () =>
                      import("@/asset/pages/assetDetailPage/TheAssetDetailContainer.vue"),
                    redirect: /* istanbul ignore next */ () => {
                      return { name: "companySiteDetailAssetDetailOverview" };
                    },
                    children: [
                      {
                        path: "overview",
                        name: "companySiteDetailAssetDetailOverview",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailOverviewView.vue"),
                        props: /* istanbul ignore next -- @preserve */ () => ({
                          assetDetailRouteName: "companySiteDetailAssetDetailOverview",
                        }),
                      },
                      {
                        path: "rooms",
                        name: "companySiteDetailAssetDetailRoomsIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailChildRoomsView.vue"),
                      },
                      {
                        path: "areas",
                        name: "companySiteDetailAssetDetailAreasIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailChildAreasView.vue"),
                      },
                      {
                        path: "assets",
                        name: "companySiteDetailAssetDetailAssetsIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailChildAssetsView.vue"),
                      },
                      {
                        path: "checks",
                        name: "companySiteDetailAssetDetailChecks",
                        component: RouterView,
                        children: [
                          {
                            path: "",
                            name: "companySiteDetailAssetDetailChecksIndex",
                            component: /* istanbul ignore next */ () =>
                              import("@/asset/views/assetDetail/AssetDetailCheckContainer.vue"),
                            props: {
                              expiredUserTaskDetailName: "companySiteDetailAssetDetailUserExpiredUserTaskDetail",
                              userTaskDetailName: "companySiteDetailAssetDetailUserTaskDetail",
                            },
                          },
                          {
                            path: "expired-user-task/:expiredUserTaskId",
                            name: "companySiteDetailAssetDetailUserExpiredUserTaskDetail",
                            component: /* istanbul ignore next */ () =>
                              import("@/task/pages/userExpiredUserTaskDetail/TheUserExpiredUserTaskDetailPage.vue"),
                            props: /* istanbul ignore next */ (route) => ({
                              expiredUserTaskId: Number(route.params["expiredUserTaskId"]),
                              indexLocation: {
                                name: "companySiteDetailAssetDetailChecksIndex",
                              },
                            }),
                          },
                          {
                            path: "user-tasks/:userTaskId",
                            name: "companySiteDetailAssetDetailUserTaskDetail",
                            component: /* istanbul ignore next */ () =>
                              import("@/task/pages/userTaskDetail/TheUserTaskDetailPage.vue"),
                            props: /* istanbul ignore next */ (route) => ({
                              userTaskId: Number(route.params["userTaskId"]),
                              indexLocation: {
                                name: "companySiteDetailAssetDetailChecksIndex",
                              },
                            }),
                          },
                        ],
                      },
                      {
                        path: "actions",
                        name: "companySiteDetailAssetDetailActionsIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/base/pages/comingSoon/BaseComingSoonPage.vue"),
                      },
                    ],
                  },
                ],
              },

              {
                path: "building-configuration",
                name: "companySiteDetailBuildingConfiguration",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getSiteIdProp(route),
                }),
                redirect: /* istanbul ignore next */ () => {
                  return { name: "companySiteDetailBuildingConfigurationOverviewIndex" };
                },
                component: /* istanbul ignore next */ () =>
                  import(
                    "@/site/views/companySiteDetailBuildingConfiguration/TheCompanySiteDetailBuildingConfigurationView.vue"
                  ),
                children: [
                  {
                    path: "overview",
                    name: "companySiteDetailBuildingConfigurationOverviewIndex",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                    }),
                    component: /* istanbul ignore next */ () =>
                      import(
                        "@/site/views/companySiteDetailBuildingConfigurationOverview/TheCompanySiteDetailBuildingConfigurationOverviewView.vue"
                      ),
                  },
                  {
                    path: ":assetId",
                    name: "companySiteDetailBuildingConfigurationAssetDetail",
                    props: /* istanbul ignore next -- @preserve */ (route) => ({
                      ...getSiteIdProp(route),
                      assetId: Number(route.params["assetId"]),
                      assetDetailRouteName: "companySiteDetailBuildingConfigurationAssetDetailOverview",
                      assetDetailRoomsRouteName: "companySiteDetailBuildingConfigurationAssetDetailRoomsIndex",
                      assetDetailAssetsRouteName: "companySiteDetailBuildingConfigurationAssetDetailAssetsIndex",
                      assetDetailAreasRouteName: "companySiteDetailBuildingConfigurationAssetDetailAreasIndex",
                      assetDetailChecksRouteName: "companySiteDetailBuildingConfigurationAssetDetailChecksIndex",
                      assetDetailActionsRouteName: "companySiteDetailBuildingConfigurationAssetDetailActionsIndex",
                      breadcrumbs: [
                        { link: { name: "companySiteDetailBuildingConfigurationOverviewIndex" }, text: "Floors" },
                      ],
                    }),
                    component: /* istanbul ignore next */ () =>
                      import("@/asset/pages/assetDetailPage/TheAssetDetailContainer.vue"),
                    redirect: /* istanbul ignore next */ () => {
                      return { name: "companySiteDetailBuildingConfigurationAssetDetailOverview" };
                    },
                    children: [
                      {
                        path: "overview",
                        name: "companySiteDetailBuildingConfigurationAssetDetailOverview",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailOverviewView.vue"),
                        props: /* istanbul ignore next -- @preserve */ () => ({
                          assetDetailRouteName: "companySiteDetailBuildingConfigurationAssetDetailOverview",
                        }),
                      },
                      {
                        path: "rooms",
                        name: "companySiteDetailBuildingConfigurationAssetDetailRoomsIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailChildRoomsView.vue"),
                      },
                      {
                        path: "areas",
                        name: "companySiteDetailBuildingConfigurationAssetDetailAreasIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailChildAreasView.vue"),
                      },
                      {
                        path: "assets",
                        name: "companySiteDetailBuildingConfigurationAssetDetailAssetsIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/asset/views/assetDetail/AssetDetailChildAssetsView.vue"),
                      },
                      {
                        path: "checks",
                        name: "companySiteDetailBuildingConfigurationAssetDetailChecks",
                        component: RouterView,
                        children: [
                          {
                            path: "",
                            name: "companySiteDetailBuildingConfigurationAssetDetailChecksIndex",
                            component: /* istanbul ignore next */ () =>
                              import("@/asset/views/assetDetail/AssetDetailCheckContainer.vue"),
                            props: {
                              expiredUserTaskDetailName:
                                "companySiteDetailBuildingConfigurationAssetDetailUserExpiredUserTaskDetail",
                              userTaskDetailName: "companySiteDetailBuildingConfigurationAssetDetailUserTaskDetail",
                            },
                          },
                          {
                            path: "expired-user-tasks/:expiredUserTaskId",
                            name: "companySiteDetailBuildingConfigurationAssetDetailUserExpiredUserTaskDetail",
                            component: /* istanbul ignore next */ () =>
                              import("@/task/pages/userExpiredUserTaskDetail/TheUserExpiredUserTaskDetailPage.vue"),
                            props: /* istanbul ignore next */ (route) => ({
                              expiredUserTaskId: Number(route.params["expiredUserTaskId"]),
                              indexLocation: {
                                name: "companySiteDetailBuildingConfigurationAssetDetailChecksIndex",
                              },
                            }),
                          },
                          {
                            path: "user-tasks/:userTaskId",
                            name: "companySiteDetailBuildingConfigurationAssetDetailUserTaskDetail",
                            component: /* istanbul ignore next */ () =>
                              import("@/task/pages/userTaskDetail/TheUserTaskDetailPage.vue"),
                            props: /* istanbul ignore next */ (route) => ({
                              userTaskId: Number(route.params["userTaskId"]),
                              indexLocation: {
                                name: "companySiteDetailBuildingConfigurationAssetDetailChecksIndex",
                              },
                            }),
                          },
                        ],
                      },
                      {
                        path: "actions",
                        name: "companySiteDetailBuildingConfigurationAssetDetailActionsIndex",
                        component: /* istanbul ignore next */ () =>
                          import("@/base/pages/comingSoon/BaseComingSoonPage.vue"),
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            path: ":siteId/documents/:documentId",
            name: "companySiteDetailDocumentDetail",
            props: /* istanbul ignore next -- @preserve */ (route) => ({
              ...getNumericProp("documentId", route),
              ...getSiteIdProp(route),
            }),
            component: /* istanbul ignore next */ () =>
              import("@/document/pages/companySiteDetailDocumentDetail/CompanySiteDetailDocumentDetailPage.vue"),
          },
        ],
      },
      {
        path: "actions",
        name: "issues",
        redirect: /* istanbul ignore next -- @preserve */ () => {
          return { name: "issuesIndex" };
        },
        children: [
          {
            path: "",
            name: "issuesIndex",
            beforeEnter: currentCompanyGuard,
            props: /* istanbul ignore next */ () => ({
              ...getAuthenticatedUserIdProp(),
            }),
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/issue/pages/issueIndex/TheIssueIndexContainer.vue"),
          },
          {
            path: ":issueId",
            name: AppRouteNames.issueDetail,
            props: /* istanbul ignore next */ (route) => {
              return {
                issueId: Number(route.params["issueId"]),
              };
            },
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/issue/pages/issueShow/TheIssueShowContainer.vue"),
          },
        ],
      },
      {
        path: "assets",
        name: "assets",
        redirect: /* istanbul ignore next -- @preserve */ () => {
          return { name: "assetsIndex" };
        },
        children: [
          {
            path: "",
            name: "assetsIndex",
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/asset/views/companyAssetIndex/CompanyAssetIndexView.vue"),
            beforeEnter: currentCompanyGuard,
          },
          {
            path: ":assetId",
            name: "assetDetail",
            props: /* istanbul ignore next -- @preserve */ (route) => ({
              assetId: Number(route.params["assetId"]),
              breadcrumbs: [{ link: { name: "assetsIndex" }, text: "Assets" }],
              assetDetailRouteName: "assetDetailOverview",
              assetDetailRoomsRouteName: "assetDetailRoomsIndex",
              assetDetailAssetsRouteName: "assetDetailAssetsIndex",
              assetDetailAreasRouteName: "assetDetailAreasIndex",
              assetDetailChecksRouteName: "assetDetailChecksIndex",
              assetDetailActionsRouteName: "assetDetailActionsIndex",
            }),
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/asset/pages/assetDetailPage/TheAssetDetailContainer.vue"),
            redirect: /* istanbul ignore next */ () => {
              return { name: "assetDetailOverview" };
            },
            children: [
              {
                path: "overview",
                name: "assetDetailOverview",
                component: /* istanbul ignore next */ () =>
                  import("@/asset/views/assetDetail/AssetDetailOverviewView.vue"),
                props: /* istanbul ignore next -- @preserve */ () => ({
                  assetDetailRouteName: "assetDetailOverview",
                }),
              },

              {
                path: "rooms",
                name: "assetDetailRoomsIndex",
                component: /* istanbul ignore next */ () =>
                  import("@/asset/views/assetDetail/AssetDetailChildRoomsView.vue"),
              },
              {
                path: "areas",
                name: "assetDetailAreasIndex",
                component: /* istanbul ignore next */ () =>
                  import("@/asset/views/assetDetail/AssetDetailChildAreasView.vue"),
              },
              {
                path: "assets",
                name: "assetDetailAssetsIndex",
                component: /* istanbul ignore next */ () =>
                  import("@/asset/views/assetDetail/AssetDetailChildAssetsView.vue"),
              },
              {
                path: "checks",
                name: "assetDetailChecks",
                component: RouterView,
                children: [
                  {
                    path: "",
                    name: "assetDetailChecksIndex",
                    component: /* istanbul ignore next */ () =>
                      import("@/asset/views/assetDetail/AssetDetailCheckContainer.vue"),
                    props: {
                      expiredUserTaskDetailName: "assetDetailUserExpiredUserTaskDetail",
                      userTaskDetailName: "assetDetailUserTaskDetail",
                    },
                  },
                  {
                    path: "expired-user-tasks/:expiredUserTaskId",
                    name: "assetDetailUserExpiredUserTaskDetail",
                    component: /* istanbul ignore next */ () =>
                      import("@/task/pages/userExpiredUserTaskDetail/TheUserExpiredUserTaskDetailPage.vue"),
                    props: /* istanbul ignore next */ (route) => ({
                      expiredUserTaskId: Number(route.params["expiredUserTaskId"]),
                      indexLocation: {
                        name: "assetDetailChecksIndex",
                      },
                    }),
                  },
                  {
                    path: "user-tasks/:userTaskId",
                    name: "assetDetailUserTaskDetail",
                    component: /* istanbul ignore next */ () =>
                      import("@/task/pages/userTaskDetail/TheUserTaskDetailPage.vue"),
                    props: /* istanbul ignore next */ (route) => ({
                      userTaskId: Number(route.params["userTaskId"]),
                      indexLocation: {
                        name: "assetDetailChecksIndex",
                      },
                    }),
                  },
                ],
              },
              {
                path: "actions",
                name: "assetDetailActionsIndex",
                component: /* istanbul ignore next */ () => import("@/base/pages/comingSoon/BaseComingSoonPage.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "dashboard",
        name: "userDashboard",
        component: /* istanbul ignore next -- @preserve  */ () =>
          import("@/home/pages/homeDashboard/HomeDashboardPage.vue"),
        redirect: /* istanbul ignore next -- @preserve */ () => {
          return { name: "userCompanyOverview" };
        },
        children: [
          {
            path: "company",
            name: "userCompanyOverview",
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/company/pages/companyOverview/CompanyOverviewPage.vue"),
            beforeEnter: currentCompanyGuard,
          },
        ],
      },
      {
        path: "rotas",
        name: "userCompanyRotas",
        component: /* istanbul ignore next -- @preserve  */ () =>
          import("@/base/pages/comingSoon/BaseComingSoonPage.vue"),
        beforeEnter: currentCompanyGuard,
      },
      {
        path: "training",
        name: "userCompanyTraining",
        component: /* istanbul ignore next -- @preserve  */ () =>
          import("@/base/pages/comingSoon/BaseComingSoonPage.vue"),
        beforeEnter: currentCompanyGuard,
      },
      {
        path: "documents",
        name: "documents",
        beforeEnter: currentCompanyGuard,
        component: RouterView,
        redirect: /* istanbul ignore next -- @preserve */ () => {
          return { name: "documentsIndex" };
        },
        children: [
          {
            path: "",
            name: "documentsIndex",
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/document/pages/companyDocumentsIndex/CompanyDocumentsIndexPage.vue"),
          },
          {
            path: ":documentId",
            name: "documentDetail",
            props: /* istanbul ignore next -- @preserve */ (route) => ({
              documentId: Number(route.params["documentId"]),
            }),
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/document/pages/companyDocumentDetail/CompanyDocumentDetailPage.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: /* istanbul ignore next -- @preserve  */ () =>
      import("@/settings/pageLayouts/settingsPageLayout/TheSettingsPageLayout.vue"),
    children: [
      {
        path: "user",
        component: RouterView,
        children: [
          {
            path: "",
            name: "userSettings",
            redirect: /* istanbul ignore next -- @preserve */ (route) => ({
              name: "userSettingsTab",
              params: { slug: "profile-settings", ...route.params },
            }),
          },
          {
            path: ":slug",
            name: "userSettingsTab",
            props: /* istanbul ignore next -- @preserve */ (route) => ({
              slug: route.params["slug"],
            }),
            component: /* istanbul ignore next -- @preserve  */ () =>
              import("@/user/pages/userSettingsTab/UserSettingsTabPage.vue"),
          },
        ],
      },
      {
        path: "company",
        name: "companiesSettings",
        beforeEnter: createCompanyPermissionGuard(PermissionEnum.companySettingsView),
        children: [
          {
            path: ":companyId",
            name: "companySettings",
            redirect: {
              name: "companySettingsGeneral",
            },
            children: [
              {
                path: "general",
                name: "companySettingsGeneral",
                component: {
                  /* istanbul ignore next -- @preserve  */
                  render() {
                    return h("div", `Company ${this.$route.params.companyId} Settings`);
                  },
                },
              },
              {
                path: "meetings",
                name: "companySettingsMeetings",
                component: {
                  /* istanbul ignore next -- @preserve  */
                  render() {
                    return h("div", `Company ${this.$route.params.companyId} Meeting Settings`);
                  },
                },
              },
              {
                path: "audits",
                name: "companySettingsAudits",
                component: {
                  /* istanbul ignore next -- @preserve  */
                  render() {
                    return h("div", `Company ${this.$route.params.companyId} Audit Settings`);
                  },
                },
              },
              {
                path: "rotas",
                name: "companySettingsRotas",
                component: {
                  /* istanbul ignore next -- @preserve  */
                  render() {
                    return h("div", `Company ${this.$route.params.companyId} Rota Settings`);
                  },
                },
              },
              {
                path: "settings",
                name: "companySettingsUpdate",
                component: /* istanbul ignore next -- @preserve  */ () =>
                  import("@/company/pages/companySettingsUpdate/TheCompanySettingsUpdatePage.vue"),
                props: /* istanbul ignore next -- @preserve  */ (route) => ({
                  companyId: Number(route.params["companyId"]),
                }),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter: LogoutGuard,
    },
  },
];

export { AppRouteNames };

export default routes;
