import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import CanPerformCompanyPermission from "@/permissions/functions/CanPerformCompanyPermission.ts";
import { createRouteLocation } from "@/app/router/functions/NavigateToErrorPage.ts";
import type { NavigationGuard, RouteLocationRaw } from "vue-router";

/**
 * Call the company permission guard
 * @param permission the permission to check
 * @returns an error route location if not authorized or do not have permission. Otherwise, returns true
 */
function createCompanyPermissionGuard(permission: string): NavigationGuard {
  // Create guard to check if the user is authenticated and has the permission.
  return (): RouteLocationRaw | boolean => {
    const authenticationStore = useAuthenticationStore();

    if (!authenticationStore.isAuthenticated) {
      return createRouteLocation(401, undefined);
    }

    if (!CanPerformCompanyPermission(permission)) {
      return createRouteLocation(403, undefined);
    }

    return true;
  };
}

export default createCompanyPermissionGuard;
