import { AppRouteNames } from "../AppRoutes.ts";

type QueryStringToRouteParamsMap = Record<string, string>;

interface BackendToFrontendRouteMapItem {
  backendRouteName: string;
  frontendRouteName: AppRouteNames;
  queryStringsToRouteParamsMap: QueryStringToRouteParamsMap;
}

type BackendToFrontendRouteMap = BackendToFrontendRouteMapItem[];

/**
 * Please be careful when updating this map.
 *
 * If routes are added, updated or removed the backend will need to be updated to match.
 * If route parameters are changed then backend routes will need to be updated to match.
 *
 * Also we store route names in the database so DO NOT change the backend route names in this file.
 *
 * Finally we store the frontend route parameters in the database for notifications.
 * If the parameters are changed then the notifications will not route correctly.
 * If this becomes are problem we may need to construct the parameters in the notifications api.
 */

const backendToFrontendRouteMap: BackendToFrontendRouteMap = [
  {
    backendRouteName: "issueDetail",
    frontendRouteName: AppRouteNames.issueDetail,
    queryStringsToRouteParamsMap: {
      issueId: "issueId",
    },
  },
];

export default backendToFrontendRouteMap;
