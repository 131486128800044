/**
 * Base application variables.
 *
 * Global variables that are used throughout the application.
 */
interface BaseAppVariables {
  /** Whether the application is running in production mode. */
  isProduction: boolean;

  /** The name of the application. */
  appName: string;
}

const setBaseAppVariables = (): BaseAppVariables => ({
  isProduction: import.meta.env.PROD,
  appName: import.meta.env["VITE_APP_NAME"],
});

const baseAppVariables = setBaseAppVariables();

// eslint-disable-next-line import/prefer-default-export
export { baseAppVariables };

export type { BaseAppVariables };
