import PermissionEnum from "@/permissions/enums/PermissionEnum.ts";
import canPerformCompanyPermission from "@/permissions/functions/CanPerformCompanyPermission.ts";
import { createRouteLocation, getRouteLocationFromAxiosError } from "@/app/router/functions/NavigateToErrorPage.ts";
import { TaskSchema } from "@/task/schemas/TaskSchema.ts";
import type { AxiosError } from "axios";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import z from "zod";

/**
 * Check if the user should continue with the original route
 * @param task - The task to check if the user should continue with the original route
 * @returns boolean Should the user continue with the original route
 */
function shouldContinueWithOriginalRoute(task: z.infer<typeof TaskSchema>): boolean {
  const currentTaskVersionId = task.current_content_version_id;

  // Do not check permissions for templates.
  if (task.is_template) {
    return false;
  }

  const hasPermission = canPerformCompanyPermission(PermissionEnum.manageUpdate);

  // Take user to task manage detail and not task version update page if they don't have permission.
  return currentTaskVersionId !== null && !hasPermission;

  // If user does not have permission and the task is not published.
  // Allow the to continue and take them to the task version update page.
  // Here the page will return a 403 error if the user does not have permission to update the task.
}

/**
 * Guard to redirect to task version update page if the task has a draft task version
 *
 * - This is because we assume the user will want to continue updating their draft
 * - Also provides a better user experience as they don't have to navigate multiple pages
 * @param to - the route being navigated to
 * @param taskVersionUpdateRoute The task version update route
 * @returns home route or true to continue
 */
export default async function taskHasDraftVersionRedirectGuard(
  to: RouteLocationNormalized,
  taskVersionUpdateRoute: (slug: string, draftTaskVersionId: number) => RouteLocationRaw
): Promise<RouteLocationRaw | boolean> {
  const { taskId } = to.params;
  const { slug } = to.params;

  let rawRes;

  try {
    rawRes = await window.httpClient.get(`/api/tasks/${taskId}`);
  } catch (error) {
    return getRouteLocationFromAxiosError(error as AxiosError);
  }

  const taskRes = z.object({ data: TaskSchema }).safeParse(rawRes.data);

  if (!taskRes.success) {
    return createRouteLocation(undefined, "Task not found");
  }

  const task = taskRes.data.data;
  const draftTaskVersionId = task.draft_task_version_id;

  if (shouldContinueWithOriginalRoute(task) || draftTaskVersionId === null) {
    return true;
  }

  if (typeof slug !== "string") {
    throw new Error("Slug is not defined.");
  }

  return taskVersionUpdateRoute(slug, draftTaskVersionId);
}
