<template>
  <div>
    <VueApexCharts type="area" :options="options" :series="series" :height="height" />
  </div>
</template>

<script lang="ts">
import type { ApexOptions } from "apexcharts";
import { initFlowbite } from "flowbite";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  name: "BaseAreaChart",
  expose: [],

  components: {
    VueApexCharts,
  },

  props: {
    /** The datasets to display on the chart */
    series: {
      type: Array as PropType<ApexOptions["series"]>,
      required: true,
    },

    /** The categories (x-axis labels) for the chart */
    categories: {
      type: Array as PropType<string[]>,
      required: true,
    },

    /** The height for this chart */
    height: {
      type: [String, Number] as PropType<Exclude<ApexOptions["chart"], undefined>["height"]>,
      required: false,
      default: undefined,
    },

    /** The x-axis options for the chart. */
    xaxis: {
      type: Object as PropType<ApexOptions["xaxis"]>,
      required: false,
      default: () => ({
        labels: {
          show: false,
        },

        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
      }),
    },

    /** The tooltip options for the chart. */
    tooltip: {
      type: Object as PropType<ApexOptions["tooltip"]>,
      required: false,
      default: () => ({
        enabled: true,
      }),
    },
  },

  computed: {
    options() {
      return {
        chart: {
          type: "area",
          fontFamily: "Inter, sans-serif",
          dropShadow: {
            enabled: false,
          },

          toolbar: {
            show: false,
          },
        },

        markers: {
          size: 3,
        },

        toolbar: {
          show: false,
        },

        zoom: {
          enabled: false,
        },

        tooltip: this.tooltip,

        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.55,
            opacityTo: 0,
            shade: "#1C64F2",
            gradientToColors: ["#1C64F2"],
          },
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          width: 6,
        },

        grid: {
          show: false,
          strokeDashArray: 4,
          padding: {
            left: 2,
            right: 2,
            top: 0,
          },
        },

        series: this.series,

        xaxis: {
          ...this.xaxis,
          categories: this.categories,
        },

        yaxis: {
          show: false,
        },
      };
    },
  },

  mounted() {
    initFlowbite();
  },
});
</script>
